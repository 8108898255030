import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}

const Testimonial = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
}) => {

    const outerClasses = classNames(
        'testimonial section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'testimonial-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
        'tiles-wrap',
        pushLeft && 'push-left'
    );

    const sectionHeader = {
        title: 'WHAT OUR CLIENTS SAY',
        paragraph: ''
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" />
                    <div className={tilesClasses}>

                        <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="testimonial-item-content">
                                    <p className="text-sm mb-0">
                                        — Definitely would recommend Alpha Dispatch services for semi-truck dispatching! I had Alpha Dispatch recommended to me by a friend and I’m glad I gave them a call
                      </p>
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                                    <span className="testimonial-item-name text-color-high">Nick Usufov</span>
                                    <span className="text-color-low"> / </span>
                                    <span className="testimonial-item-link">
                                        Trans Motor Group LLC 
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-bottom">
                            <div className="tiles-item-inner">
                                <div className="testimonial-item-content">
                                    <p className="text-sm mb-0">
                                        — This is the best trucking dispatch company out there! Alex and his team did an amazing job with dispatching high paying freight!! I drive on weekdays and I always have loads ready for me Monday!
                      </p>
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                                    <span className="testimonial-item-name text-color-high">Willie Brown</span>
                                    <span className="text-color-low"> / </span>
                                    <span className="testimonial-item-link">
                                        ALDCARGO LLC
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-left" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="testimonial-item-content">
                                    <p className="text-sm mb-0">
                                        — Alpha Dispatch are best truck dispatchers I’ve worked with in the last 2 years. Their dispatching services is top-notch offering best loads on a consistent basis. Very nice and easy to work with. Wonderful freight dispatch service. Would recommend them.

                      </p>
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                                    <span className="testimonial-item-name text-color-high">Sean Fisher</span>
                                    <span className="text-color-low"> / </span>
                                    <span className="testimonial-item-link">
                                        BRB Transport
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;