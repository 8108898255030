import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import Button from '../elements/Button';
import emailjs from 'emailjs-com';
import ReactGA from 'react-ga';

const propTypes = {
    ...SectionProps.types,
    split: PropTypes.bool
}

const defaultProps = {
    ...SectionProps.defaults,
    split: false
}

const Cta = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    split,
    ...props
}) => {

    const [emailProcessing, updateEmailProcessing] = useState(false);
    const [emailSent, updateEmailSent] = useState(false);

    const [companyName, updateCompanyName] = useState("");
    const [phoneNumber, updatePhoneNumber] = useState("");
    const [email, updateEmail] = useState("");

    const onCompanyNameUpdate = (event) => {
        let input = event.target.value;
        updateCompanyName(input);
    };

    const onPhoneNumberUpdate = (event) => {
        let input = event.target.value;
        updatePhoneNumber(input);
    };

    const onEmailUpdate = (event) => {
        let input = event.target.value;
        updateEmail(input);
    };

    const sendEmailHandler = () => {
        ReactGA.event({
            category: "Contact",
            action: "Submit",
            label: "Data submitted"
        });

        updateEmailProcessing(true);
        emailjs.send('service_lt91v26', 'template_iabn5hy', {
            company_name: companyName,
            phone_number: phoneNumber,
            email: email,
        }, 'user_Z0CWFJYdDCZhcmXe5vr9q')
            .then((result) => {
                updateEmailProcessing(false);
                updateEmailSent(true);
            }, (error) => {
                updateEmailProcessing(false);
                updateEmailSent(true);
            });
    };

    /*===============Init==================*/
    useEffect(() => {
        //init("user_Z0CWFJYdDCZhcmXe5vr9q");


    }, []);

    const outerClasses = classNames(
        'cta section center-content-mobile reveal-from-bottom',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'cta-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider',
        split && 'cta-split'
    );

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                {emailSent &&

                    <div id='cta-success' className={innerClasses} >
                        <div className="cta-slogan">
                            <h3 className="m-0">
                                We appreciate your collaboration. <br />
                            Alpha Dispatch team will come back to you shortly!
              </h3>

                    </div>
                </div>
                    }
                {!emailSent &&

                    <div id='cta-inner' className={innerClasses} >
                        <div className="cta-slogan">
                            <h3 className="m-0">
                                Ready to work with us?
                        </h3>
                            
                    </div>
                        {emailProcessing && <div className="loader">Loading...</div>}

                        {!emailProcessing && !emailSent &&
                            <div className="cta-action">
                                <Input type="text" id='contact-us' label="Company name" placeholder="Company name" name="" onChange={onCompanyNameUpdate}>

                                </Input>

                                <Input type="tel" label="Phone number" placeholder="Phone number" onChange={onPhoneNumberUpdate}>

                                </Input>

                                <Input type="email" label="Email" placeholder="Email" onChange={onEmailUpdate}>

                                </Input>


                                <Button variant="primary" onClick={sendEmailHandler}>Send</Button>{' '}
                            </div>
                        }

                    </div>}
            </div>
        </section>
    );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;