import React from 'react';
import ReactDOM from 'react-dom'
import { faTruckPickup } from "@fortawesome/free-solid-svg-icons";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { faTruckMoving } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}
const GrossSection = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
}) => {

    const outerClasses = classNames(
        '   padding-0',
        'features-split section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-split-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
        'tiles-wrap center-content',
        pushLeft && 'push-left'
    );

    const sectionHeader = {
        title: 'GROSS WE GUARANTEE',
        paragraph: 'per week'
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content padding-0" />
                    <div className={tilesClasses}>
                       

                        <div className="tiles-item reveal-from-bottom">
                            <div className="tiles-item-inner padding-0">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <FontAwesomeIcon icon={faTruckPickup} />
                                    </div>
                                </div>
                                
                                <div className="features-tiles-item-content">
                                    <h4 className="mt-0 mb-8">
                                        3 car trailer
                                    </h4>
                                    
                                </div>
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16 gross-cell">
                                        <div className="gross">
                                            6k $+
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-bottom">
                            <div className="tiles-item-inner padding-0">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <FontAwesomeIcon icon={faTruck} />
                                    </div>
                                </div>
                                <div className="features-tiles-item-content">
                                    <h4 className="mt-0 mb-8">
                                        4-5 car trailer

                                    </h4>

                                </div>
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16 gross-cell">
                                        <div className="gross">
                                            8k $+
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-bottom">
                            <div className="tiles-item-inner padding-0">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <FontAwesomeIcon icon={faTruckMoving} />
                                    </div>
                                </div>
                                <div className="features-tiles-item-header">
                                    
                                </div>
                                <div className="features-tiles-item-content">
                                    <h4 className="mt-0 mb-8">
                                        7+ car trailer

                                    </h4>

                                </div>
                                <div className="features-tiles-item-image mb-16 gross-cell">
                                    <div className="gross">
                                        10k $+
                                            </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

GrossSection.propTypes = propTypes;
GrossSection.defaultProps = defaultProps;

export default GrossSection;